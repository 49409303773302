<template>
  <v-container fluid>
    <v-row v-if="showHeaderPage" class="mt-5">
      <v-col cols="12" md="3" align="start">
        <p class="title">Informações Complementares</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="3">
        <div class="d-flex flex-column">
          <v-text-field
            outlined
            label="Data de Nascimento"
            v-model="beneficiary.birthDate"
            append-icon="fas fa-calendar-alt"
            placeholder="DD/MM/YYYY"
            v-mask="'##/##/####'"
            color="textPrimary"
            :disabled="isDetail"
            :rules="beneficiary.birthDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
            validate-on-blur
            @input="observerHolderComplementaryData()"
          />
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="propsMaritalStatus"
          v-model="beneficiary.maritalStatus"
          label="Estado Civil"
          placeholder="Escolha o estado civil"
          item-text="description"
          item-value="id"
          outlined
          hide-details
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="propsGenders"
          v-model="beneficiary.gender"
          label="Gênero"
          placeholder="Escolha o gênero"
          item-text="description"
          item-value="id"
          outlined
          hide-details
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Nome da mãe"
          placeholder="Informe o nome da Mãe"
          maxlength="70"
          v-model.trim="beneficiary.motherName"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="beneficiary.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
          validate-on-blur
          @input="beneficiary.motherName = formatter.formatToTitleCase(beneficiary.motherName); observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="CNS"
          placeholder="Informe o CNS"
          v-model="beneficiary.cns"
          v-mask="'###############'"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="beneficiary.cns ? [rule.cns] : []"
          validate-on-blur
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Declaração de Nascido Vivo (DNV)"
          placeholder="Informe o DNV"
          v-mask="'##-########-#'"
          v-model="beneficiary.dnv"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="beneficiary.dnv ? [rule.dnv] : []"
          validate-on-blur
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Nome da empresa"
          v-model.trim="beneficiary.companyName"
          :disabled="isDetail"
          placeholder="Informe o nome"
          color="textPrimary"
          outlined
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="CNPJ da empresa"
          v-model="beneficiary.companyDocumentNumber"
          :disabled="isDetail"
          v-mask="'##.###.###/####-##'"
          placeholder="CNPJ da empresa"
          color="textPrimary"
          outlined
          :rules="beneficiary.companyDocumentNumber ? [rule.cnpj] : []"
          validate-on-blur
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Departamento"
          placeholder="Informe o departamento"
          maxlength="255"
          v-model="beneficiary.department"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="[rule.required]"
          validate-on-blur
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Código de Lotação / Centro de Custo"
          placeholder="Informe o código de lotação"
          maxlength="255"
          v-model="beneficiary.capacityCode"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          validate-on-blur
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Descrição de Lotação / Centro de Custo"
          placeholder="Informe a descrição de lotação"
          maxlength="255"
          v-model="beneficiary.capacityDescription"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          validate-on-blur
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="employmentRelationships"
          v-model="beneficiary.employmentRelationship"
          label="Vínculo Empregaticio"
          placeholder="Escolha o vínculo"
          item-text="description"
          item-value="id"
          outlined
          hide-details
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Carteira de Trabalho"
          placeholder="Informe a carteira de trabalho"
          v-mask="'#######'"
          v-model="beneficiary.workCard"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="PIS / PASEP"
          placeholder="Informe o PIS / PASEP"
          v-mask="'###.#####.##-#'"
          v-model="beneficiary.pisPasep"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Cargo"
          placeholder="Informe o cargo"
          v-model="beneficiary.job"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <template v-if="showRegistrationPlate">
        <v-col cols="12" md="3">
          <v-text-field
            label="Matrícula"
            placeholder="Informe a matrícula"
            v-model="beneficiary.registrationPlate"
            maxlength="50"
            outlined
            color="textPrimary"
            :disabled="isDetail"
            @input="observerHolderComplementaryData()"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Complemento da Matrícula"
            placeholder="Informe o complemento"
            v-model="beneficiary.registrationComplement"
            maxlength="50"
            outlined
            color="textPrimary"
            :disabled="isDetail"
            @input="observerHolderComplementaryData()"
          />
        </v-col>
      </template>
      <v-col cols="12" md="3">
        <v-text-field
          label="Salário"
          placeholder="Informe o valor"
          ref="salary"
          v-model="beneficiary.salary"
          prefix="R$"
          @keydown="$event.key === '-' ? $event.preventDefault() : null"
          @blur="verifyNegative(beneficiary.salary)"
          id="fieldChecked"
          v-money="money"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="[rule.validateNegativeDecimal(beneficiary.salary, 'int'), rule.validateSalary]"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          :items="nationalitys"
          v-model="beneficiary.nationality"
          label="Nacionalidade"
          placeholder="Escolha a nacionalidade"
          item-text="text"
          item-value="value"
          outlined
          hide-details
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="beneficiary.stateBirth"
          :items="states"
          item-text="label"
          item-value="label"
          outlined
          label="UF de Nascimento"
          color="textPrimary"
          item-color="textPrimary"
          append-icon="fas fa-chevron-down"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Peso(kg)"
          v-mask="'###'"
          type="number"
          min="1"
          placeholder="Informe o peso"
          v-model="beneficiary.weight"
          @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
          @input="beneficiary.weight = formatter.removeInvalidNumber(beneficiary.weight); observerHolderComplementaryData()"
          @blur="validateNumbersGreaterThanOne(beneficiary.weight, 'fieldCheckedWeight')"
          id="fieldCheckedWeight"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="controlWeight ? ['Favor informar um número válido'] : beneficiary.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(beneficiary.weight)] : []"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Altura(cm)"
          v-mask="'###'"
          type="number"
          min="1"
          placeholder="Informe a altura"
          v-model="beneficiary.height"
          @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
          @input="beneficiary.height = formatter.removeInvalidNumber(beneficiary.height); observerHolderComplementaryData()"
          @blur="validateNumbersGreaterThanOne(beneficiary.height, 'fieldCheckedHeight')"
          id="fieldCheckedHeight"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="controlHeight ? ['Favor informar um número válido'] : beneficiary.height ? [rule.validatesNumberGreaterThanOrEqualToZero(beneficiary.height)] : []"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="RNE/RNM"
          v-mask="'#########'"
          placeholder="Informe o RNE/RNM"
          v-model="beneficiary.rne"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderComplementaryData()"
        />
      </v-col>
      <v-col cols="12">
        <label class="title">Dados de Contato</label>
        <v-row class="my-5">
          <v-col cols="12" md="3">
            <v-text-field
              label="Telefone Residencial"
              placeholder="Informe o número"
              v-model="beneficiary.telephone"
              lazy-validation
              v-mask="'(##) ####-####'"
              :rules="beneficiary.telephone ? [rule.telephone] : []"
              validate-on-blur
              outlined
              color="textPrimary"
              :disabled="isDetail"
              @input="observerHolderComplementaryData()"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="Celular"
              placeholder="Informe o número"
              v-model="beneficiary.cellphone"
              v-mask="'(##) #####-####'"
              :rules="beneficiary.cellphone ? [rule.cellphone] : []"
              validate-on-blur
              outlined
              color="textPrimary"
              :disabled="isDetail"
              @input="observerHolderComplementaryData()"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              label="E-Mail"
              placeholder="exemplo@email.com.br"
              :rules="beneficiary.email ? [rule.email] : []"
              v-model="beneficiary.email"
              outlined
              color="textPrimary"
              :disabled="isDetail"
              validate-on-blur
              @input="observerHolderComplementaryData()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <label class="title">Identidade</label>
        <v-row class="my-5">
          <v-col cols="3">
            <label class="label">Natureza</label>
            <v-autocomplete
              class="mt-2"
              v-model="beneficiary.identityDocumentNature"
              :items="identityDocumentNatureTypes"
              :disabled="isDetail"
              @input="beneficiary.identityDocumentNumber = null; observerHolderComplementaryData()"
              placeholder="Informe a natureza do documento"
              color="textPrimary"
              outlined
              clearable
            />
          </v-col>
          <v-col cols="3">
            <label class="label">Documento</label>
            <v-text-field
              v-model="beneficiary.identityDocumentNumber"
              :rules="beneficiary.identityDocumentNumber ? [rule.rgMaxDigit(beneficiary.identityDocumentNature, beneficiary.identityDocumentNumber)] : []"
              validate-on-blur
              v-mask="'NNNNNNNNNNNNNNN'"
              class="mt-2"
              placeholder="Informe o número do documento"
              outlined
              color="textPrimary"
              :disabled="isDetail"
              @input="observerHolderComplementaryData()"
            />
          </v-col>
          <v-col cols="3">
            <label class="label">Data de Expedição</label>
            <div class="d-flex flex-column">
              <v-text-field
                outlined
                v-model="beneficiary.identityDocumentEmissionDate"
                append-icon="fas fa-calendar-alt"
                placeholder="DD/MM/YYYY"
                v-mask="'##/##/####'"
                color="textPrimary"
                class="mt-2"
                :disabled="isDetail"
                :rules="beneficiary.identityDocumentEmissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                validate-on-blur
                @input="observerHolderComplementaryData()"
              />
            </div>
          </v-col>
          <v-col cols="3">
            <label class="label">Orgão Emissor</label>
            <v-text-field
              maxlength="8"
              v-mask="'AAAAAAAA'"
              @input="beneficiary.identityDocumentIssuer = beneficiary.identityDocumentIssuer.toUpperCase(); observerHolderComplementaryData()"
              v-model="beneficiary.identityDocumentIssuer"
              class="mt-2"
              placeholder="Informe o órgão emissor"
              outlined
              color="textPrimary"
              :disabled="isDetail"
            />
          </v-col>
        </v-row>
        <v-row class="my-5">
          <v-col cols="3">
            <label class="label">UF Emissor</label>
            <v-autocomplete
              v-model="beneficiary.identityDocumentState"
              :items="states"
              class="mt-2"
              item-text="label"
              item-value="label"
              outlined
              placeholder="UF emissor"
              color="textPrimary"
              item-color="textPrimary"
              append-icon="fas fa-chevron-down"
              :disabled="isDetail"
              clearable
              @input="observerHolderComplementaryData()"
            />
          </v-col>
          <v-col cols="3">
            <label class="label">País Emissor</label>
            <v-autocomplete
              v-model="beneficiary.identityDocumentCountry"
              :disabled="isDetail"
              :items="country"
              class="mt-2"
              item-text="text"
              item-value="value"
              outlined
              placeholder="País emissor"
              color="textPrimary"
              item-color="textPrimary"
              append-icon="fas fa-chevron-down"
              clearable
              @input="observerHolderComplementaryData()"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VMoney } from 'v-money';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import EmploymentRelationshipService from '@/services-http/sdi/EmploymentRelationshipService';

export default ({
  name: 'ComplementaryInformations',

  directives: { money: VMoney },

  data: () => ({
    controlHeight: false,
    controlWeight: false,

    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2,
      masked: false,
    },
    beneficiary: {},

    employmentRelationshipService: null,
    employmentRelationships: [],
  }),

  props: {
    holderComplementaryData: {
      type: Object,
      required: false,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    showHeaderPage: {
      type: Boolean,
      required: true,
    },
    isFreeMovement: {
      type: Boolean,
      required: true,
    },
    propsGenders: {
      type: Array,
      required: true,
    },
    propsMaritalStatus: {
      type: Array,
      required: true,
    },
    showRegistrationPlate: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    holderComplementaryData: {
      handler(val) {
        if (val) {
          this.loadHolderComplementaryDataForProps(val);
        }
      },
      deep: true,
    },
  },

  mixins: [
    ConstantsMixin,
  ],

  async mounted() {
    await this.loadEmploymentRelationship();
    this.loadHolderComplementaryDataForMovement();
  },

  methods: {
    async loadEmploymentRelationship() {
      await this.employmentRelationshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.employmentRelationships = response.data;
        }
      });
    },
    loadHolderComplementaryDataForMovement() {
      if (sessionStorage.getItem('movementRecord')) {
        const movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.beneficiary.birthDate = movementRecord.birthDate ? this.formatter.formatDate(movementRecord.birthDate) : null;
        this.beneficiary.maritalStatus = movementRecord.maritalStatus ? movementRecord.maritalStatus : null;
        this.beneficiary.gender = movementRecord.gender ? movementRecord.gender : null;
        this.beneficiary.motherName = movementRecord.motherName ? this.formatter.formatToTitleCase(movementRecord.motherName) : null;
        this.beneficiary.cns = movementRecord.cns ? movementRecord.cns : null;
        this.beneficiary.dnv = movementRecord.dnv ? movementRecord.dnv : null;
        this.beneficiary.companyName = movementRecord.companyName ? this.formatter.formatToTitleCase(movementRecord.companyName) : null;
        this.beneficiary.companyDocumentNumber = movementRecord.companyDocumentNumber ? movementRecord.companyDocumentNumber : null;
        this.beneficiary.department = movementRecord.costCenterDepartment ? movementRecord.costCenterDepartment : null;
        this.beneficiary.capacityCode = movementRecord.taxAllocationCode ? movementRecord.taxAllocationCode : null;
        this.beneficiary.capacityDescription = movementRecord.taxAllocationDescription ? movementRecord.taxAllocationDescription : null;
        this.beneficiary.employmentRelationship = movementRecord.employmentRelationshipId ? movementRecord.employmentRelationshipId : null;
        this.beneficiary.workCard = movementRecord.professionalCardNumber ? movementRecord.professionalCardNumber : null;
        this.beneficiary.pisPasep = movementRecord.pisPasep ? movementRecord.pisPasep : null;
        this.beneficiary.job = movementRecord.insuredPosition ? movementRecord.insuredPosition : null;
        this.beneficiary.registrationPlate = movementRecord.registrationPlate;
        this.beneficiary.registrationComplement = movementRecord.registrationComplement;
        this.beneficiary.nationality = movementRecord.nationality ? movementRecord.nationality : null;
        this.beneficiary.stateBirth = movementRecord.stateBirth ? movementRecord.stateBirth : null;
        this.beneficiary.weight = movementRecord.weight ? movementRecord.weight : null;
        this.beneficiary.height = movementRecord.height ? movementRecord.height : null;
        this.beneficiary.rne = movementRecord.rne ? movementRecord.rne : null;
        this.beneficiary.telephone = movementRecord.homephoneDDD && movementRecord.homephoneNumber ? movementRecord.homephoneDDD + movementRecord.homephoneNumber : null;
        this.beneficiary.cellphone = movementRecord.cellphoneDDD && movementRecord.cellphoneNumber ? movementRecord.cellphoneDDD + movementRecord.cellphoneNumber : null;
        this.beneficiary.email = movementRecord.email ? movementRecord.email : null;
        this.beneficiary.identityDocumentNature = movementRecord.natureIdentifyDocument;
        this.beneficiary.identityDocumentNumber = movementRecord.rgDocumentIdentify;
        this.beneficiary.identityDocumentEmissionDate = movementRecord.rgDocumentIssueDate ? this.formatter.formatDate(movementRecord.rgDocumentIssueDate) : null;
        this.beneficiary.identityDocumentIssuer = movementRecord.rgDocumentIssuingBody;
        this.beneficiary.identityDocumentState = movementRecord.documentStateIssuer;
        this.beneficiary.identityDocumentCountry = movementRecord.documentCountryIssue;
        this.beneficiary.salary = movementRecord.salary;
        this.formatSalary();
      }
    },
    loadHolderComplementaryDataForProps(val) {
      const beneficiaryCopy = { ...val };
      beneficiaryCopy.birthDate = beneficiaryCopy.birthDate ? this.formatter.formatDate(beneficiaryCopy.birthDate) : null;
      beneficiaryCopy.identityDocumentEmissionDate = beneficiaryCopy.identityDocumentEmissionDate ? this.formatter.formatDate(beneficiaryCopy.identityDocumentEmissionDate) : null;
      this.beneficiary = beneficiaryCopy;
      this.formatSalary();
    },
    formatSalary() {
      let salaryFormattedString = this.beneficiary.salary ? this.beneficiary.salary.toFixed(2) : null;
      if (salaryFormattedString) {
        salaryFormattedString = salaryFormattedString.replaceAll('.', ',');
        const inputSalary = this.$refs.salary.$el.querySelector('input');
        inputSalary.value = salaryFormattedString;
        this.beneficiary.salary = salaryFormattedString;
      }
    },
    verifyNegative(value) {
      let formatValue = value;
      formatValue = formatValue.replace(',', '.');
      if (parseFloat(formatValue) < 0) {
        document.getElementById('fieldChecked').focus();
      }
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    observerHolderComplementaryData() {
      this.$emit('observerHolderComplementaryData', this.beneficiary);
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.employmentRelationshipService = new EmploymentRelationshipService();
  },
});
</script>
